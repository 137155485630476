import React from "react";
import securedByXPayLogo from "../../assets/securedByXpay.svg";
import BackButton from "./BackButton";
const Header = ({
  setLoadingMain,
  mode,
  merchantLogo,
  callbackUrl,
  xIntentId,
  subscriptionId,
}) => {
  const handleImageLoad = () => {
    setLoadingMain(false);
  };

  return (
    <div className="relative pb-7">
      <div className="flex items-center justify-between">
        {merchantLogo ? (
          <>
            <img
              src={merchantLogo}
              alt="xPay logo"
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              className="max-h-10 max-w-20"
            />

            {mode === "TEST" && (
              <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs absolute left-[calc((100vw-130px)/2)] sm:left-[168px]">
                Test Mode
              </div>
            )}
            <div>
              <img
                src={securedByXPayLogo}
                alt="xPay logo"
                className="w-20 h-10"
              />
            </div>
          </>
        ) : (
          <>
            <img
              src={securedByXPayLogo}
              alt="xPay logo"
              className="w-20 h-10"
            />
            {mode === "TEST" && (
              <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs">
                Test Mode
              </div>
            )}
          </>
        )}
      </div>
      <BackButton
        callbackUrl={callbackUrl}
        xIntentId={xIntentId}
        subscriptionId={subscriptionId}
      />
    </div>
  );
};

export default Header;
