/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { IoWarningOutline } from "react-icons/io5";
import { Modal } from "antd";
import { Button } from "antd";

const BackButton = ({ callbackUrl, xIntentId, subscriptionId }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    if (callbackUrl) {
      if (xIntentId) {
        window.location.href = callbackUrl + "?xpay_intent_id=" + xIntentId;
      } else {
        window.location.href =
          callbackUrl + "?subscription_id=" + subscriptionId;
      }
    } else {
      if (window.history.length > 3) {
        window.history.go(-3);
      } else {
        window.history.go(-2);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, "", window.location.href);
      handleClickOpen();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleClose}
        footer={null}
        centered
        closable={false}
        width={328}
      >
        <div className="flex flex-col items-center justify-center gap-6 py-1">
          <div className="text-yellow-500 bg-[#FFF7D3] p-4 w-fit rounded-full">
            <IoWarningOutline size={36} />
          </div>
          <div className="font-sans text-lg font-normal text-center">
            Are you sure you want to leave? <br />
            Your payment is almost finished!
          </div>
          <div className="grid w-full grid-cols-2 gap-2 mt-4">
            <Button
              className="w-full text-black bg-[#E6E6E6] font-sans"
              size="large"
              onClick={handleBack}
            >
              Yes
            </Button>
            <Button
              className="w-full font-sans text-white bg-black"
              size="large"
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
      <IoCloseOutline
        size={20}
        className="absolute cursor-pointer sm:right-[-2rem] sm:top-[-1rem] right-[-0.2rem] top-[-1.3rem]"
        onClick={handleClickOpen}
      />
    </div>
  );
};

export default BackButton;
